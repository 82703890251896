<template>
	<div class="page-container">
		<div class="page-head">
			<ul class="head-center">
				<li><span class="title">{{liveInfo.title}}</span></li>
				<li>
					<el-tag type="danger" size="mini" effect="dark">{{$t('liveplatform.liveInfo.status')}}</el-tag>
				</li>
				<li><span class="time">{{liveTimestamp}}</span></li>
			</ul>
			<ul class="head-action">
				<li>
					<el-button @click="handleEndLive" type="danger" size="small" round>{{$t('liveplatform.liveInfo.endBtn')}}</el-button>
				</li>
				<li>
					<a class="uulive-btn" @click="handleRefreshUrl">{{$t('liveplatform.liveInfo.openBtn')}}</a>
				</li>
			</ul>
		</div>
		<div class="page-main">
			<!-- <div class="page-preview">
				<div class="preview-main">
					<div class="preview-box">
						<video id="uuvideo" class="live-video" v-show="livePreview" src="" controls="controls"></video>
						<div class="preview-float" v-show="!livePreview">
							<el-button type="danger" @click="handleLivePreview" round size="small">开始预览</el-button>
						</div>
					</div>
					<div class="preview-bottom">
						<el-input v-model="value2" type="textarea" :rows="4"></el-input>
					</div>
				</div>
			</div> -->
			<div class="page-wrap">
				<div class="page-title"><span>{{$t('liveplatform.liveInfo.paneName1')}}</span>
				</div>
				<div class="wrap-middle">
					<div class="item">
						<div class="item-label"><img class="icon" src="@/assets/images/live/live-icon-1.png"
								alt=""><span>{{$t('liveplatform.liveInfo.paneText1-1')}}</span></div>
						<div class="item-value">{{panelData.onlline_num?panelData.onlline_num:0}}</div>
					</div>
					<div class="item">
						<div class="item-label"><img class="icon" src="@/assets/images/live/live-icon-2.png"
								alt=""><span>{{$t('liveplatform.liveInfo.paneText1-2')}}</span></div>
						<div class="item-value">{{panelData.new_fans_num?panelData.new_fans_num:0}}</div>
					</div>
					<div class="item">
						<div class="item-label"><img class="icon" src="@/assets/images/live/live-icon-3.png"
								alt=""><span>{{$t('liveplatform.liveInfo.paneText1-3')}}</span></div>
						<div class="item-value">{{panelData.view_count_num?panelData.view_count_num:0}}</div>
					</div>
					<div class="item">
						<div class="item-label"><img class="icon" src="@/assets/images/live/live-icon-4.png"
								alt=""><span>{{$t('liveplatform.liveInfo.paneText1-4')}}</span></div>
						<div class="item-value">{{panelData.trans_amount_num?panelData.trans_amount_num:0}}</div>
					</div>
				</div>
				<div id="myChart" style="height: 400px;"></div>
			</div>
			<div class="page-bar">
				<div class="page-title">
					<span>{{$t('liveplatform.liveInfo.paneName2')}}</span>
					<el-button type="danger" size="mini" round class="next-btn" @click="handleGoods">+商品</el-button>
				</div>
				<el-scrollbar>
					<div class="page-goods" v-if="goodsList.length>0">
						<div class="item" v-for="(item,index) in goodsList" :key="index">
							<div class="pic"><img :src="item.defaultImage" alt=""></div>
							<div class="item-main">
								<div class="name">{{item.goodsName}}</div>
								<div class="price">¥{{item.price}}</div>
							</div>
						</div>
					</div>
					<el-empty :description="$t('liveplatform.empty.noGoods')" v-else></el-empty>
				</el-scrollbar>
			</div>
		</div>
		<el-dialog
			:title="$t('liveplatform.live.dialogTitle3')"
			:visible.sync="dialogVisible"
			width="960px"
			class="live-dialog"
		>
			<el-checkbox-group v-model="checkGoods" class="shop-goods">
				<el-checkbox v-for="(item, index) in shopGoods" :key="index" :label="item.goodsId">
					<div class="item">
						<img class="item-pic" :src="item.defaultImage" alt="">
						<div class="item-main">
							<div class="name">{{ item.goodsName }}</div>
							<div class="price">￥{{ item.price }}</div>
						</div>
					</div>
				</el-checkbox>
			</el-checkbox-group>
			<div class="dialog-page">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="pageCurr"
					:page-size="pageSize"
					layout="total, prev, pager, next"
					:prev-text="$t('liveplatform.pagination.prevText')"
					:next-text="$t('liveplatform.pagination.nextText')"
					:total="pageTotal">
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="dialogVisible = false" round>{{ $t('liveplatform.live.dialogCancel') }}</el-button>
				<el-button size="small" type="danger" @click="handleSubmit()" round>{{ $t('liveplatform.live.dialogConfirm2') }}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import * as echarts from 'echarts';
	import { fetchLive, fetchShopGoods, fetchLiveGoods, uploadLiveGoods, openPushFlow, endLive, fetchAliyunAuthToken } from '@/api/liveplatform';
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				liveInfo: {},
				panelData: {},
				goodsList: {},
				liveTimestamp: '00:00:00',
				livePreview: false,
				rtcService: null,
				dialogVisible: false,
				shopGoods: [],
				pageCurr: 1,
				pageSize: 12,
				pageTotal: 0,
				checkGoods: [],
				domeList: [
					["2022-02-05 12:05:00", 116],
					["2022-02-05 12:10:00", 129],
					["2022-02-05 12:15:00", 135],
					["2022-02-05 12:20:00", 86],
					["2022-02-05 12:25:00", 73],
					["2022-02-05 12:30:00", 85],
					["2022-02-05 12:35:00", 73],
					["2022-02-05 12:40:00", 68],
					["2022-02-05 12:45:00", 92],
					["2022-02-05 12:50:00", 130],
					["2022-02-05 12:55:00", 245],
					["2022-02-05 13:00:00", 139],
					["2022-02-05 13:05:00", 115],
					["2022-02-05 13:10:00", 111],
					["2022-02-05 13:15:00", 309],
					["2022-02-05 13:20:00", 206],
					["2022-02-05 13:20:00", 137],
					["2022-02-05 13:25:00", 128],
					["2022-02-05 13:30:00", 85],
					["2022-02-05 13:35:00", 94],
					["2022-02-05 13:40:00", 71],
					["2022-02-05 13:45:00", 106],
					["2022-02-05 13:50:00", 84],
					["2022-02-05 13:55:00", 93],
					["2022-02-05 14:00:00", 85],
					["2022-02-05 14:05:00", 73],
					["2022-02-05 14:10:00", 83],
					["2022-02-05 14:15:00", 125],
					["2022-02-05 14:20:00", 107],
					["2022-02-05 14:25:00", 82],
					["2022-02-05 14:30:00", 44]
				],
				value2: '',
			}
		},
		mounted() {
			this.getLiveInfo();
			this.getGoodsList();
			// this.initEcharts();
		},
		methods: {
			getLiveInfo() {
				let timestamp = Date.parse(new Date()) / 1000;
				let param = {
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey,
					uuid: this.$store.getters.uuid
				}
				fetchLive(param).then(response => {
					if (parseInt(response.data.status) !== 1) {
						this.$router.push({name: 'LiveList'});
					}
					this.liveInfo = response.data;
					this.panelData = response.data.live_statistics;
					setInterval(() => {
						this.onLiveTimestamp();
					}, 1000);
				}).catch(() => {
					this.$router.push({name: 'LiveList'});
				})
			},
			getGoodsList() {
				let param = {
					liveId: this.$route.params.id,
					page_index: 0,
					page_size: 20,
					app_key: this.$store.getters.appKey
				}
				fetchLiveGoods(param).then(response => {
					this.goodsList = response.data.list;
				})
			},
			handleRefreshUrl(){
				let that = this;
				if(that.rtcService){
					that.$confirm(this.$t('liveplatform.confirmMsg.openToolText'), this.$t('liveplatform.confirmMsg.title'), {
						confirmButtonText: this.$t('liveplatform.confirmMsg.continueBtn'),
						cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
						type: 'warning'
					}).then(() => {
						that.rtcService.stopRtcPreview();
						that.livePreview = false;
						that.$nextTick(()=>{
							this.onRefreshUrl();
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: this.$t('liveplatform.cancelMsg.default')
						});
					});
				}else {
					this.onRefreshUrl();
				}
			},
			onRefreshUrl(){
				let param = {
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				let alertText = this.$t('liveplatform.alertMsg.liveTool');
				openPushFlow(param).then(response => {
					window.protocolCheck(
						response.data.standard_room_jump_url,
						function() {
							alert(alertText);
						}
					);
					setTimeout(()=>{
						this.rtcService = null;
					},5000)
				}).catch(() => {
					this.rtcService = null;
				})
			},
			onLiveTimestamp() {
				let rowDate = parseInt(new Date() / 1000);
				let longTime = rowDate - this.liveInfo.settime;
				if(longTime>0){
					let dd = longTime / 86400;
					let longH = longTime % 86400;
					let hh = longH / 3600;
					let longM = longH % 3600;
					let mm = longM / 60;
					let ss = longM % 60;
					hh = parseInt(hh) < 10 ? '0' + parseInt(hh) : parseInt(hh);
					mm = parseInt(mm) < 10 ? '0' + parseInt(mm) : parseInt(mm);
					ss = parseInt(ss) < 10 ? '0' + parseInt(ss) : parseInt(ss);
					if (parseInt(dd) > 0) {
						this.liveTimestamp = parseInt(dd) + this.$t('liveplatform.unit.day')+ " " + hh + ":" + mm + ":" + ss;
					} else {
						this.liveTimestamp = hh + ":" + mm + ":" + ss;
					}
				}else{
					this.liveTimestamp = "00:00:00";
				}
			},
			initEcharts() {
				const dateList = this.domeList.map(function(item) {
					return item[0];
				});
				const valueList = this.domeList.map(function(item) {
					return item[1];
				});
				var myChart = echarts.init(document.getElementById('myChart'));
				// // 绘制图表
				myChart.setOption({
					visualMap: {
						show: false,
						type: 'continuous',
					},
					title: {
						text: this.$t('liveplatform.liveInfo.chartTitle'),
						textStyle: {
							color: "#ffffff",
							fontWeight: "normal",
							fontSize: "14"
						}
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						// type: 'time',
						data: dateList,
					},
					yAxis: {
						splitLine: {
							show: false
						},
						axisLine: {
							show: true
						}
					},
					series: [{
						type: 'line',
						showSymbol: false,
						smooth: true,
						data: valueList
					}]
				});
			},
			handleLivePreview(){
				const { RoomEngine, EventNameEnum } = window.RoomPaasSdk;
				// 获取RoomEngine实例
				const roomEngine = RoomEngine.getInstance();// 获取设备号deviceId，唯一标识当前设备
				// 在web端，不同的tab页代表不同设备
				const deviceId = roomEngine.getDeviceId();
				// 如果场景确保一个设备只有一个页面，需要使用getSingleDeviceId
				// const deviceId = roomEngine.getSingleDeviceId();
				const appKey = '2f0c39fbbbd7d47e3585b7e9defa4230';
				const appId = '82c0vp';
				const that = this;
				const authTokenCallback = () => {
					return new Promise((resolve,reject)=>{
						fetchAliyunAuthToken().then(response => {
							resolve(response.data)
						}).catch(error => {
							reject(error)
						})
					})
				}
				// 配置参数
				const config = {
					appKey, // 创建应用时分配的appKey
					appId, // 创建应用时分配的appId
					deviceId, // 设备号
					authTokenCallback, // 获取登录token的回调函数，需要返回Promise
				}
				// 传入配置
				roomEngine.init(config);
				roomEngine.auth(that.userInfo.userId).then(() => {
					// roomId标识房间号，方法调用结果获取当前房间roomChannel实例
					const roomChannel = roomEngine.getRoomChannel(that.liveInfo.aliyun_live_id);
					// 获取Rtc插件实例
					that.rtcService = roomChannel.getPluginService('rtc');
					that.rtcService.isSupport().then(res=>{
						that.livePreview = true;
						// 获取设备信息
						that.rtcService.getDeviceInfo();
						// 开始摄像头预览
						let videoDom = document.getElementById('uuvideo');
						that.rtcService.startRtcPreview(videoDom);
						// that.rtcService.stopRtcPreview();
					}).catch((error)=>{
						if(error.errorCode==10022){
							that.$message.error(this.$t('liveplatform.errorMsg.noAudio'));
						}else {
							console.log(`${error.message}`);
						}
					});
				});
			},
			handleEndLive() {
				this.$confirm(this.$t('liveplatform.confirmMsg.endLiveText'), this.$t('liveplatform.confirmMsg.endLiveTitle'), {
					confirmButtonText: this.$t('liveplatform.confirmMsg.confirmBtn'),
					cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
					type: 'warning'
				}).then(() => {
					let param = {
						liveId: this.$route.params.id,
						status: 2,
						app_key: this.$store.getters.appKey
					}
					endLive(param).then(() => {
						this.$router.push({
							name: 'LivePlayback',
							params: {
								id: this.$route.params.id
							}
						});
						this.$message.success(this.$t('liveplatform.successMsg.end'));
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.$t('liveplatform.cancelMsg.end')
					});
				});
			},
			handleGoods() {
				this.getShopGoods()
				this.checkGoods = []
				this.dialogVisible = true
			},
			getShopGoods() {
				const params = {
          page_index:	(this.pageCurr-1)*this.pageSize,
          page_size: this.pageSize,
					app_key: this.$store.getters.appKey
				}
				fetchShopGoods(params).then(response => {
					this.shopGoods = response.data.list
					this.pageTotal = parseInt(response.data.total)
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getShopGoods()
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getShopGoods()
			},
			handleSubmit() {
				if(!this.checkGoods.length>0) {
					this.$message.error(this.$t('liveplatform.errorMsg.selectGoods'))
					return false
				}
				this.$confirm(this.$t('liveplatform.confirmMsg.addGoodsText'),this.$t('liveplatform.confirmMsg.title'),{
					confirmButtonText: this.$t('liveplatform.confirmMsg.confirmBtn'),
					cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
					type: 'warning'
				}).then(() => {
					const params = {
						goodsId: this.checkGoods.join(','),
						liveId: this.liveInfo.live_id,
						app_key: this.$store.getters.appKey
					}
					uploadLiveGoods(params).then(() => {
						this.$message.success(this.$t('liveplatform.successMsg.add'))
						this.getGoodsList()
						this.dialogVisible = false
					})
				}).catch(() => {
					this.$message(this.$t('liveplatform.cancelMsg.add'))
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-head {
		padding: 0 10px 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.head-center {
			li {
				display: inline-block;
				margin-left: 8px;

				::v-deep .el-tag--danger {
					border: none;
					padding: 0 10px;
					border-radius: 10px;
					background: $--live-tag-danger-shading;
				}
			}

			.title {
				font-size: 16px;
				color: #FFFFFF;
			}

			.time {
				color: $--live-text-color-2;
				font-size: 12px;
			}
		}

		.head-action {
			li {
				margin-left: 10px;
				display: inline-block;
			}

			.el-button--danger {
				border-color: $--live-button-primary-border;
				background-color: $--live-button-primary-background;
				&:focus,&:hover {
					border-color: $--live-button-primary-hover-border;
					background-color: $--live-button-primary-hover-background;
				}
			}

			.uulive-btn {
				padding: 9px 15px;
				border: 1px solid $--live-button-gray-border;
				background-color: $--live-button-gray-background;
				border-radius: 20px;
				font-size: 12px;
				line-height: 1em;
				color: #FFFFFF;
				display: inline-block;
				cursor: pointer;
			}
		}
	}

	.page-main {
		display: flex;
		padding: 0 10px;
		min-width: 1280px;

		.page-preview {
			width: 320px;
			position: relative;

			.preview-main {
				border-radius: 6px;
				padding: 20px 10px;
				background-color: $--live-background-default;

				.preview-box {
					position: relative;
					height: 420px;
					.live-video {
						width: 100%;
						height: 420px;
						border-radius: 6px;
					}
					.preview-float {
						top: 194px;
						left: 50%;
						transform: translateX(-50%);
						position: absolute;
					}
				}

				.preview-bottom {
					margin-top: 10px;

					::v-deep .el-textarea {
						.el-textarea__inner {
							color: $--live-text-color-2;
							resize: none;
							border-radius: 6px;
							border-color: rgba(0, 0, 0, .5);
							background-color: rgba(0, 0, 0, .5);
						}
					}
				}
			}
		}

		.page-wrap {
			flex: 1;
			width: 0;
			min-width: 660px;
			margin: 0 10px;
			background-color: $--live-gray-black-17;
			min-height: calc(100vh - 143px);

			.wrap-middle {
				height: 118px;
				border-radius: 6px;
				margin-bottom: 10px;
				padding: 5px 30px;
				display: flex;
				flex-wrap: wrap;
				background-color: $--live-background-default;
				justify-content: space-between;
				align-items: center;
				margin: 15px;

				.item {
					width: 50%;
					flex-shrink: 0;

					.item-label {
						line-height: 18px;

						.icon {
							width: 16px;
							height: 16px;
							margin-right: 8px;
						}

						span {
							color: #FFFFFF;
							opacity: .6;
							font-size: 12px;
						}
					}

					.item-value {
						font-size: 18px;
						color: #FFFFFF;
						margin-top: 3px;
						padding-left: 20px;
					}
				}
			}
		}

		.page-title {
			color: #FFFFFF;
			height: 34px;
			line-height: 34px;
			font-size: 16px;
			padding: 10px 20px;
			border-radius: 6px 6px 0 0;
			background-color: $--live-gray-white-6;
			.next-btn {
				float: right;
				color: $--live-button-info-font;
				border-color: $--live-button-info-border;
				background-color: transparent;
				&:hover {
					color: $--live-button-default-hover-font;
					border-color: $--live-button-default-hover-border;
					background-color: transparent;
				}
			}
		}

		.page-bar {
			height: calc(100vh - 143px);
			width: 360px;
			background-color: $--live-background-default;
			border-radius: 6px;
			.el-scrollbar {
				height: calc(100% - 54px)!important;
			}
		}

		.page-goods {
			padding: 20px 10px;

			.item {
				display: flex;
				margin-bottom: 20px;

				.pic {
					width: 86px;
					height: 86px;
					position: relative;
					border-radius: 6px;
					overflow: hidden;
					margin-right: 8px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.item-main {
					width: 0;
					flex: 1;

					.name {
						font-size: 14px;
						color: #FFFFFF;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 4px;
					}

					.price {
						font-size: 14px;
						color: $--live-link-color-1;
						margin-top: 26px;
					}
				}
			}
		}
	}
	
	.shop-goods {
		::v-deep .el-checkbox {
			width: 136px;
			border-radius: 6px;
			position: relative;
			margin: 5px;
			border: 1px solid transparent;
			.el-checkbox__input {
				top: 10px;
				right: 10px;
				position: absolute;
			}
			.el-checkbox__inner {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				&:after {
					top: 3px;
					left: 6px;
					width: 4px;
					height: 8px;
					border-right-width: 2px;
					border-bottom-width: 2px;
				}
			}
			.el-checkbox__label {
				padding-left: 0;
			}
			&.is-checked {
				border-color: $--live-checkbox-primary;
			}
		}
		::v-deep .el-checkbox__input {
			&.is-checked {
				.el-checkbox__inner {
					background-color: $--live-checkbox-primary;
					border-color: $--live-checkbox-primary;
				}
			}
			&.is-checked+.el-checkbox__label {
				color: $--live-text-color-2;
			}
		}
		.item {
			.item-pic {
				width: 136px;
				height: 136px;
				border-radius: 6px;
				object-fit: cover;
			}
			.item-main {
				font-size: 12px;
				padding: 6px;
				.name {
					color: $--live-text-color-2;
					height: 36px;
					line-height: 18px;
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					text-overflow: ellipsis;
					white-space: normal;
				}
				
				.price {
					color: $--live-link-color-1;
					line-height: 18px;
				}
			}
		}
	}
	.dialog-page {
		text-align: center;
		padding-top: 10px;
		::v-deep .el-pagination {
			color: $--live-text-color-2;
			.el-pagination__total {
				color: $--live-gray-white-20;
			}
			.btn-next,.btn-prev {
				color: $--live-text-color-2;
				background-color: transparent;
			}
			.btn-next:hover,.btn-prev:hover {
				color: $--live-link-hover-1;
			}
			button:disabled {
				color: $--live-gray-white-20!important;
			}
			.el-pager {
				li {
					background-color: transparent;
					&.btn-quicknext {
						color: $--live-text-color-2;
					}
					&:hover,&.active {
						color: $--live-link-hover-1;
					}
				}
			}
		}
	}
</style>
